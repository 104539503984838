import { DefaultOptionType } from 'antd/es/select';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setExcludeIsSparesModel } from 'redux/services/hermanTheMop/hermanTheMopParams';
import { useAppSelector } from 'redux/store';
import { FancySelect } from './FancySelect';

export const IsSparesModelFilter: FC = () => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const selectOptions: DefaultOptionType[] = ['TRUE', 'FALSE'].map((option) => ({ label: option, value: option }));

  const { excludeIsSparesModel } = useAppSelector((state) => state.hermanTheMopParams);

  const selectValue = (): string | undefined => {
    if (excludeIsSparesModel === true) {
      return 'FALSE';
    }
    if (excludeIsSparesModel === false) {
      return 'TRUE';
    }

    return undefined;
  };

  const handleChange = (value: string): void => {
    if (value?.toLowerCase() === 'true') {
      dispatch(setExcludeIsSparesModel(false));

      return;
    }
    if (value?.toLowerCase() === 'false') {
      dispatch(setExcludeIsSparesModel(true));

      return;
    }
    dispatch(setExcludeIsSparesModel(undefined));
  };

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setExcludeIsSparesModel(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FancySelect value={selectValue()} width={185} options={selectOptions} placeholder={'Is Spares Model'} onChange={handleChange} />;
};
