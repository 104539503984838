import { Avatar, List, Space, Typography } from 'antd';
import MdsiIcon from 'assets/mdsi-icon.svg';
import { Business } from 'models/Business';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { InlineStylesModel } from '../../models/InlineStylesModel';

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    paddingLeft: 10,
    borderRadius: 5,
    marginBottom: 10,
    cursor: 'pointer'
  },
  container: {
    alignItems: 'center'
  },
  title: {
    fontWeight: '100',
    marginBottom: 0,
    margin: 0,
    padding: 0
  },
  altTitle: {
    fontWeight: '100',
    color: '#18a799',
    marginBottom: 0,
    margin: 0,
    padding: 0
  }
};

type Props = {
  business: Business;
};

export const BusinessListItem: FC<Props> = ({ business }): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { pathname } = useLocation();
  const nav = useNavigate();

  const isRecycleBinPage = pathname.includes('recycling-bin');

  /* ******************** Functions ******************** */
  const handleClick = (): void => {
    nav(`/details/${business.id}`);
  };

  // Render
  return (
    <List.Item onClick={handleClick} style={styles.wrapper}>
      <List.Item.Meta
        avatar={<Avatar src={business.logoUrl ? business.logoUrl : MdsiIcon} />}
        title={
          <Space>
            <Typography.Title level={5} style={styles.title}>
              {business.name}
            </Typography.Title>
            <Typography.Title level={5} type="secondary" style={styles.title}>
              |
            </Typography.Title>
            <Typography.Title level={5} type="secondary" style={styles.altTitle}>
              {business.erpId}
            </Typography.Title>
          </Space>
        }
        style={styles.container}
      />
      {/* 
      <Button disabled={!business.divisions.length} style={{ width: 110, marginRight: 8, padding: 0, border: 'none' }} onClick={handleToggleShowDivision} key="show-divisions">
        {showDivision ? 'Collapse' : 'Expand'}
      </Button> */}
    </List.Item>
  );
};
