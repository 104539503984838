import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, Row, Space, Tooltip, Typography } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { ProductCatalogItem } from 'models/ProductCatalog';
import { useDispatch } from 'react-redux';
import { setProduct, setProductSubstitue } from 'redux/slices/productSubSlice';
import { useAppSelector } from 'redux/store';
const { Text, Paragraph } = Typography;

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
    // cursor: 'pointer'
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    height: '100%'
  },
  description: {
    fontSize: '0.8em',
    display: 'flex'
    // height: 55
  }
};

interface Props {
  product: ProductCatalogItem;
}

export const ProductSearchItem = ({ product }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  // const { selectedProducts } = useAppSelector((state) => state.productSearch);
  const dispatch = useDispatch();
  const { product: primaryProduct, productSubstitue } = useAppSelector((state) => state.productSubSlice);

  const handleToggleSelectProduct = (): void => {
    if (primaryProduct?.productNumber === product.productNumber) {
      dispatch(setProduct(undefined));

      return;
    }
    if (product.productNumber === productSubstitue?.productNumber) {
      dispatch(setProductSubstitue(undefined));
      dispatch(setProduct(product));

      return;
    }
    dispatch(setProduct(product));
  };

  const isSelected = primaryProduct?.productNumber === product.productNumber;

  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      <Card
        size="small"
        headStyle={{ padding: 8 }}
        bodyStyle={{ padding: 8 }}
        style={styles.container}
        extra={<Button onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />}
        title={
          <Tooltip>
            <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
              {product.productNumber}
            </Typography.Title>
          </Tooltip>
        }>
        <Row gutter={[8, 8]} align="top">
          <Col span={24}>
            <Tooltip title={product.productDescription}>
              <Text ellipsis type="secondary" style={styles.description}>
                {product.productDescription}
              </Text>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Space size={1} direction="vertical">
              <Text>Category</Text>
              <Text type="secondary">{product.category || 'NA'}</Text>
            </Space>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space size={1} direction="vertical">
              <Text>Manufacturer</Text>
              <Row justify={'end'}>
                <Text type="secondary">{product.manufacturer || 'NA'}</Text>
              </Row>
            </Space>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
