import { Row, Space, Tabs, TabsProps, Typography } from 'antd';
import { AddSubstituteButton } from 'components/atoms/AddSubstituteButton';
import { ImportExportButtonGroup } from 'components/molecules/ImportExportButtonGroup';
import { SubstitutesTable } from 'components/molecules/SubstitutesTable';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTabIndex } from 'redux/slices/appSlice';
import { resetObjectState } from 'redux/slices/productSubSlice';
import { useAppSelector } from 'redux/store';
import { AddSubstitutionsPage } from './AddSubstitutionsPage';

export const Details = (): JSX.Element => {
  const { tabIndex } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Substitutes',
      children: <SubstitutesTable />
    },
    {
      key: '2',
      label: 'Add Substitutes',
      children: <AddSubstitutionsPage />
    }
  ];

  useEffect(() => {
    return () => {
      dispatch(setTabIndex('1'));
    };
  }, []);

  const isAddPage = tabIndex === '2';

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify={'space-between'}>
        <Typography.Title level={4} style={{ margin: 0, padding: 0 }}>
          {isAddPage ? 'Add Substitutions' : 'Substitutions'}
        </Typography.Title>
      </Row>
      {/* {!isAddPage && <SubstitutesFilters />} */}
      <Tabs
        tabBarExtraContent={
          <Space size={3}>
            {!isAddPage && <ImportExportButtonGroup />}
            {isAddPage && <AddSubstituteButton />}
          </Space>
        }
        onChange={(activeKey): void => {
          dispatch(setTabIndex(activeKey));
          if (activeKey === '1') {
            dispatch(resetObjectState());
          }
        }}
        type="card"
        activeKey={tabIndex}
        defaultActiveKey={tabIndex}
        items={items}
      />
    </Space>
  );
};
