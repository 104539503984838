import { Card, Modal, Spin, Typography, notification } from 'antd';
import { Loader } from 'components/common/Loader';
import { LottieButton } from 'components/molecules/LottieButton';
import { SubstituteForm } from 'components/molecules/SubstituteForm';
import { FormikProvider, useFormik } from 'formik';
import { QueryErrorModel } from 'models/ErrorModel';
import { Substitution, SubstitutionPayload, substitutionValidation } from 'models/Substitutions';
import { FC, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateSubstituteItemMutation } from 'redux/services/hermanTheMop/hermanTheMopApi';
import editLottie from '../../common/assets/edit-lottie.json';

type Props = {
  record: Substitution;
};

export const EditRecordModal: FC<Props> = ({ record }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const [updateSubstitution] = useUpdateSubstituteItemMutation();
  const formik = useFormik<SubstitutionPayload>({
    enableReinitialize: true,
    validationSchema: substitutionValidation,
    initialValues: {
      substitutionType: record.substitutionType ?? '',
      itemId: record?.item.productNumber ?? '',
      SubstituteItemId: record.substituteItem?.productNumber ?? '',
      isSparesModel: record.isSparesModel ?? '',
      substitutionOrderType: record.substitutionOrderType ?? ''
    },
    onSubmit: async (values) => {
      const body: Omit<SubstitutionPayload, 'SubstituteItemId' | 'itemId'> = {
        substitutionType: values.substitutionType,
        isSparesModel: values.isSparesModel,
        substitutionOrderType: values.substitutionOrderType
      };

      if (!id)
        return notification.error({
          message: 'Error',
          description: 'No business id found. Please try again.',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });

      try {
        await updateSubstitution({ businessId: id, substituteItemId: record.id, payload: body }).unwrap();
        notification.success({
          message: 'Success',
          description: 'Substitution updated successfully.',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
        setIsOpen(false);
        formik.resetForm();
      } catch (e) {
        console.error(e);
        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
        setIsOpen(false);
        formik.resetForm();
      }
    }
  });

  return (
    <Fragment>
      <LottieButton
        style={{ borderRadius: '50%', background: '#FFEFCC', borderColor: '#F8AD48' }}
        animationData={editLottie}
        lottieStyle={{ width: 20, height: 20, display: 'inline-block', transform: 'scale(1.0)' }}
        onClick={(): void => setIsOpen(true)}
      />
      <Modal
        open={isOpen}
        closable={false}
        onOk={formik.submitForm}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}>
        <FormikProvider value={formik}>
          <Spin spinning={formik.isSubmitting} indicator={<Loader height="100%" loadingMessage="Updating Substitution" />}>
            <Card
              headStyle={{ backgroundColor: 'rgb(255, 234, 193)' }}
              title={
                <Typography.Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
                  Edit Substitution
                </Typography.Title>
              }
              bodyStyle={{ minHeight: '100%' }}
              style={{ height: '100%', minHeight: '100%', width: '100%' }}>
              <SubstituteForm />
            </Card>
          </Spin>
        </FormikProvider>
      </Modal>
    </Fragment>
  );
};
