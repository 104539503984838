import { BusinessList } from 'components/organisms/BusinessList';
import { About } from 'pages/About';
import { Create } from 'pages/Create';
import { Details } from 'pages/Details';
import { EditProfilePage } from 'pages/EditProfile';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<BusinessList />} />
      <Route path="/create" element={<Create />} />
      <Route path="/details/:id" element={<Details />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile/" element={<EditProfilePage />} />
    </Routes>
  );
};
