import { Col, Row } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { FieldInputSwitch } from 'components/atoms/FieldInputSwitch';
import { FC } from 'react';

export const SubstituteForm: FC = () => {
  return (
    <Row gutter={[50, 10]}>
      <Col span={24}>
        <FieldInput disabled label="Product Number" fieldName="itemId" />
      </Col>
      <Col span={24}>
        <FieldInput disabled label="Substitute Product Number" fieldName="SubstituteItemId" />
      </Col>
      <Col span={24}>
        <FieldInputSelect options={['Substitution', 'Interchangeable'].map((item) => ({ label: item, value: item }))} label="Substitution Type" fieldName="substitutionType" />
      </Col>
      <Col span={24}>
        <FieldInputSelect
          options={['All Orders', 'Deployment Orders', 'Sparing Orders'].map((item) => ({ label: item, value: item }))}
          label="Substitution Order Type"
          fieldName="substitutionOrderType"
        />
      </Col>
      {/* <Col span={24}>
        <FieldInputSwitch label="Is Active" fieldName="isActive" />
      </Col> */}
      <Col span={24}>
        <FieldInputSwitch label="Is Spares Model" fieldName="isSparesModel" />
      </Col>
    </Row>
  );
};
