import React from 'react';
import '../../common/styles/loaderStyle.less';

interface DirectionProps {
  direction: string;
  style?: React.CSSProperties;
}

export const BlockLoader = ({ direction, style }: DirectionProps): JSX.Element => {
  return (
    <div className={direction} style={style}>
      <span className="loader-item">1</span>
      <span className="loader-item">2</span>
      <span className="loader-item">3</span>
      <span className="loader-item">4</span>
      <span className="loader-item">5</span>
      <span className="loader-item">6</span>
    </div>
  );
};
