import { Space, Switch } from 'antd';
import { SwitchProps } from 'antd/lib';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends SwitchProps {
  fieldName: string;
  placeholder?: string;
  label: string;
}

export const FieldInputSwitch: FC<Props> = ({ fieldName, placeholder, label, ...rest }) => {
  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <Space direction="vertical">
            <FormLabel label={label} />
            <Switch checked={meta.value} {...field} {...rest} onChange={(checked) => setFieldValue(fieldName, checked)} />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </Space>
        );
      }}
    </Field>
  );
};
