import { Row, Space, Typography } from 'antd';
import { ProductNumberFilter } from 'components/atoms/ProductSearchFilters/ProductNumberFilter';
import { FC } from 'react';

export const ProductSearchFilters: FC = () => {
  return (
    <Row style={{ background: 'white', padding: 12 }}>
      <Space>
        <Typography.Text>Select Product</Typography.Text>
        <ProductNumberFilter />
      </Space>
    </Row>
  );
};
