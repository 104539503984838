import { Col, List, Row, Space, Typography } from 'antd';
import { FancyInput } from 'components/atoms/FancyInput';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { BusinessListItem } from 'components/molecules/BusinessListItem';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useGetBusinessNameConatinsQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setCustomerSearch } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

export const BusinessList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { customerSearch } = useAppSelector((state) => state.app);
  const { abbyCadabbyParams } = useAppSelector((state) => state);

  const { data, isLoading, isFetching, isError, error } = useGetBusinessNameConatinsQuery(abbyCadabbyParams);

  const handleCustomerSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setCustomerSearch(event.target.value));
  };

  // Clears state on unmount

  useEffect(() => {
    return (): void => {
      dispatch(setCustomerSearch(undefined));
    };
  }, [dispatch]);

  const businessList = data?.data;

  const filteredData = useMemo(() => {
    if (!customerSearch) return businessList;

    return businessList?.filter((business) => business.name.toLocaleLowerCase().includes(customerSearch.toLocaleLowerCase()));
  }, [businessList, customerSearch]);

  console.log(filteredData);

  /* ******************** Render ******************** */
  return (
    <div style={{ marginTop: 10 }}>
      <Row align={'middle'} justify="space-between">
        <Space>
          <Col>
            <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
              Customers
            </Typography.Title>
          </Col>
          <Col>
            <FancyInput placeholder="Search customer" onChange={handleCustomerSearchChange} />
          </Col>
        </Space>
      </Row>
      <List
        loading={{
          indicator: <LoaderWithMessage loadingMessage="Loading customers" />,
          size: 'large',
          spinning: isLoading || isFetching
        }}
        dataSource={filteredData}
        renderItem={(item): JSX.Element => <BusinessListItem business={item} />}
        rowKey={(item: any): string => item.id}
        style={{ marginTop: 16 }}
      />
    </div>
  );
};
