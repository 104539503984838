import { GeneralInfoCard } from 'components/molecules/GeneralInfoCard';
import { FormikProvider, useFormik } from 'formik';

export const Create = (): JSX.Element => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerPurchaseOrder: '',
      vendorId: '',
      requestedDeliveryDate: '',
      siteId: '',
      warehouseId: ''
    },
    onSubmit: (values) => {
      console.log(values);
    }
  });

  return (
    <FormikProvider value={formik}>
      <GeneralInfoCard />
    </FormikProvider>
  );
};
