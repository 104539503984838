import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Col, Row } from 'antd';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ProductSearchFilters } from 'components/molecules/ProductSearchFilters';
import { ProductSearchFiltersSub } from 'components/molecules/ProductSearchFiltersSub';
import { ProductSearchPage } from 'components/organisms/ProductSearch';
import { ProductSearchSub } from 'components/organisms/ProductSearchSub';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetSubstituteItemsQuery } from 'redux/services/hermanTheMop/hermanTheMopApi';
import { useGetProductCatalogItemsQuery } from 'redux/services/julia/juliaApi';
import { resetObjectState } from 'redux/slices/productSubSlice';
import { useAppSelector } from 'redux/store';

export const AddSubstitutionsPage = (): JSX.Element => {
  const { productCatalogParamsSub } = useAppSelector((state) => state);
  const { juliaProductCatalogParams } = useAppSelector((state) => state);
  const dispatch = useDispatch();

  const { id } = useParams();

  const { isLoading, isError, error } = useGetProductCatalogItemsQuery({ customerId: id as string, params: { ...juliaProductCatalogParams, ...productCatalogParamsSub } }, { skip: !id });
  const { data, isLoading: isSubsLoading, isError: isSubsError, error: subsError } = useGetSubstituteItemsQuery({ businessId: id as string, params: { overrideSkipTake: true } }, { skip: !id });

  useEffect(() => {
    return () => {
      dispatch(resetObjectState());
    };
  }, []);

  if (isLoading || isSubsLoading) return <LoaderWithMessage loadingMessage="Loading Products" />;

  if (isError || isSubsError) {
    const errData = (error ?? subsError) as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <Row style={{ marginTop: 10 }} gutter={[100, 100]}>
      <Col span={12}>
        <ProductSearchFilters />
        <ProductSearchPage />
      </Col>
      <Col span={12}>
        <ProductSearchFiltersSub />
        <ProductSearchSub subsData={data?.data ?? []} />
      </Col>
    </Row>
  );
};
