import { Button, Card, Modal, Spin, Typography, notification } from 'antd';
import { Loader } from 'components/common/Loader';
import { SubstituteForm } from 'components/molecules/SubstituteForm';
import { FormikProvider, useFormik } from 'formik';
import { QueryErrorModel } from 'models/ErrorModel';
import { SubstitutionPayload, substitutionValidation } from 'models/Substitutions';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCreateSubstituteItemMutation } from 'redux/services/hermanTheMop/hermanTheMopApi';
import { setTabIndex } from 'redux/slices/appSlice';
import { resetObjectState } from 'redux/slices/productSubSlice';
import { useAppSelector } from 'redux/store';

export const AddSubstituteButton: FC = () => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { product: primaryProduct, productSubstitue } = useAppSelector((state) => state.productSubSlice);
  const [createSubstitution] = useCreateSubstituteItemMutation();
  const dispatch = useDispatch();

  const formik = useFormik<SubstitutionPayload>({
    enableReinitialize: true,
    validationSchema: substitutionValidation,
    initialValues: {
      substitutionType: 'Substitution',
      itemId: primaryProduct?.productNumber ?? '',
      SubstituteItemId: productSubstitue?.productNumber ?? '',
      isSparesModel: true,
      substitutionOrderType: ''
    },
    onSubmit: async (values) => {
      const body: SubstitutionPayload = { ...values, itemId: primaryProduct?.id ?? '', SubstituteItemId: productSubstitue?.id ?? '' };

      if (!id)
        return notification.error({
          message: 'Error',
          description: 'No business id found. Please try again.',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });

      try {
        await createSubstitution({ businessId: id, payload: body }).unwrap();
        notification.success({
          message: 'Success',
          description: 'Substitution added successfully.',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
        setIsOpen(false);
        dispatch(setTabIndex('1'));
        dispatch(resetObjectState());
        formik.resetForm();
      } catch (e) {
        console.error(e);
        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
        setIsOpen(false);
        formik.resetForm();
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Button onClick={(): void => setIsOpen(true)} disabled={!primaryProduct || !productSubstitue} type="primary">
        Assign
      </Button>
      <Modal
        confirmLoading={formik.isSubmitting}
        onOk={(): Promise<void> => formik.submitForm()}
        closable={false}
        open={isOpen}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}>
        <Spin spinning={formik.isSubmitting} indicator={<Loader height="100%" loadingMessage="Creating Substitution" />}>
          <Card
            headStyle={{ backgroundColor: 'rgb(255, 234, 193)' }}
            title={
              <Typography.Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
                Add Substitute for {primaryProduct?.productNumber}
              </Typography.Title>
            }
            bodyStyle={{ minHeight: '100%' }}
            style={{ height: '100%', minHeight: '100%', width: '100%' }}>
            <SubstituteForm />
          </Card>
        </Spin>
      </Modal>
    </FormikProvider>
  );
};
