import * as yup from 'yup';

export type SubstitutionPayload = {
  itemId: string;
  SubstituteItemId: string;
  substitutionOrderType: string;
  substitutionType: string;
  isSparesModel: boolean;
};
export type SubstitutionImportPayload = {
  itemId: string;
  SubstituteItemId: string;
  substitutionOrderType: string;
  substitutionType: string;
  isSparesModel: string;
};

export type Substitution = {
  business: {
    dataAreaId: string;
    erpId: string;
    id: string;
    name: string;
  };
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  isSparesModel: boolean;
  item: {
    category: string;
    id: string;
    manufacturer: string;
    productDescription: string;
    productName: string;
    productNumber: string;
  };
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  substituteItem: {
    category: string;
    id: string;
    manufacturer: string;
    productDescription: string;
    productName: string;
    productNumber: string;
  };
  substitutionOrderType: string;
  substitutionType: string;
};

export const substitutionValidation: yup.SchemaOf<SubstitutionPayload> = yup.object({
  itemId: yup.string().label('Product Number').required(),
  SubstituteItemId: yup.string().label('Substitute Product Number').required(),
  isSparesModel: yup.boolean().label('Is Spares Model').required(),
  substitutionOrderType: yup.string().label('Substitution Order Type').required(),
  substitutionType: yup.string().label('Substitution Type').required()
});
export const substitutionImportValidation: yup.SchemaOf<SubstitutionImportPayload> = yup.object({
  itemId: yup.string().label('Product Number').required(),
  SubstituteItemId: yup.string().label('Substitute Product Number').required(),
  isSparesModel: yup.string().label('Is Spares Model').required(),
  substitutionOrderType: yup.string().label('Substitution Order Type').required(),
  substitutionType: yup.string().label('Substitution Type').required()
});
