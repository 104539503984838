import { Row, Space } from 'antd';
import { IsSparesModelFilter } from 'components/atoms/IsSparesModelFilter';
import { QueryParamFilter } from 'components/atoms/QueryParamFilter';
import { QueryParamFilterSelect } from 'components/atoms/QueryParamFilterSelect';
import { FC } from 'react';
import { setItemIdContains, setSubstituteItemIdContains, setSubstitutionOrderTypes, setSubstitutionTypes } from 'redux/services/hermanTheMop/hermanTheMopParams';
import { useAppSelector } from 'redux/store';

export const SubstitutesFilters: FC = () => {
  const { substitutionTypes, substitutionOrderTypes } = useAppSelector((state) => state.hermanTheMopParams);

  return (
    <Row style={{ background: 'white', padding: 18 }}>
      <Space>
        <QueryParamFilter placeholder="Product Number" filterAction={setItemIdContains} />
        <QueryParamFilter width={235} placeholder="Substitution Product Number" filterAction={setSubstituteItemIdContains} />
        <QueryParamFilterSelect options={['Substitution', 'Interchangeable']} placeholder="Type" filterAction={setSubstitutionTypes} width={185} value={substitutionTypes} />
        <IsSparesModelFilter />
        <QueryParamFilterSelect
          options={['All Orders', 'Deployment Orders', 'Sparing Orders']}
          placeholder="Order Types"
          value={substitutionOrderTypes}
          width={185}
          filterAction={setSubstitutionOrderTypes}
        />
      </Space>
    </Row>
  );
};
