import { Row, Space, Typography } from 'antd';
import { ProductNumberFilterSub } from 'components/atoms/ProductSearchFilters/ProductNumberFilterSub';
import { FC } from 'react';

export const ProductSearchFiltersSub: FC = () => {
  return (
    <Row style={{ background: 'white', padding: 12 }}>
      <Space>
        <Typography.Text>Select Product to Substitute</Typography.Text>
        <ProductNumberFilterSub />
      </Space>
    </Row>
  );
};
