import { Space, Spin } from 'antd';
import { BlockLoader } from './BlockLoader';

export const LoadingMore = (): JSX.Element => {
  return (
    <Space style={{ width: '100%', justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
      <Spin indicator={<BlockLoader direction="loader loader--slideUp" />} spinning />
    </Space>
  );
};
