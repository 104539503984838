import { DefaultOptionType } from 'antd/es/select';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FancySelect } from './FancySelect';

type Props = {
  placeholder: string;
  filterAction: (value?: string) => void;
  width?: number;
  options: string[];
  value?: string;
};

export const QueryParamFilterSelect: FC<Props> = ({ placeholder, filterAction, options, width, value }) => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const selectOptions: DefaultOptionType[] = options.map((option) => ({ label: option, value: option }));

  const handleChange = (value: string): void => {
    dispatch(filterAction(value));
  };

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(filterAction(undefined));
    };
  }, []);

  return <FancySelect value={value} width={width} options={selectOptions} placeholder={placeholder} onChange={handleChange} />;
};
