import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from './FancyInput';

type Props = {
  placeholder: string;
  filterAction: (value?: string) => void;
  width?: number;
};

export const QueryParamFilter: FC<Props> = ({ placeholder, filterAction, width }) => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(filterAction(value));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(filterAction(undefined));
    };
  }, []);

  return <FancyInput width={width} placeholder={placeholder} onChange={handleChange} />;
};
