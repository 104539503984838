import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { List } from 'antd';
import { LoadingMore } from 'components/atoms/LoadMore';
import { ProductSearchItemSub } from 'components/atoms/ProductSearchItemSub';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { Substitution } from 'models/Substitutions';
import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetProductCatalogItemsQuery } from 'redux/services/julia/juliaApi';
import { incrementOffset } from 'redux/services/julia/productCatalogParamsSub';
import { useAppSelector } from 'redux/store';

type Props = {
  subsData: Substitution[];
};

export const ProductSearchSub: FC<Props> = ({ subsData }) => {
  const dispatch = useDispatch();

  /* ******************** API Hooks ******************** */
  const { productCatalogParamsSub } = useAppSelector((state) => state);

  const { id } = useParams();

  const { data, isLoading, isFetching, isError, error } = useGetProductCatalogItemsQuery({ customerId: id as string, params: productCatalogParamsSub }, { skip: !id });

  /* ******************** Functions / Variables ******************** */
  const hasMore = Boolean(data && data.totalCount > data.data.length);

  const handleLoadMore = (): void => {
    dispatch(incrementOffset());
  };

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <div id="subTarget" style={{ height: '65vh', overflowX: 'scroll' }}>
      <InfiniteScroll scrollableTarget="subTarget" next={handleLoadMore} hasMore={hasMore} loader={<LoadingMore />} dataLength={data ? data.data.length : 0}>
        <List
          loading={{
            indicator: <LoaderWithMessage loadingMessage="Loading Products" />,
            spinning: isLoading || (isFetching && productCatalogParamsSub.offset === 0),
            size: 'large',
            wrapperClassName: isLoading ? 'list-loading' : ''
          }}
          grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
          dataSource={data?.data}
          renderItem={(item): JSX.Element => <ProductSearchItemSub subsData={subsData} product={item} />}
          rowKey={(item): string => item.productNumber}
        />
      </InfiniteScroll>
    </div>
  );
};
