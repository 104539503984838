import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Modal, Space, Table, Typography, notification } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { EditRecordModal } from 'components/atoms/EditRecordModal';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { QueryErrorModel } from 'models/ErrorModel';
import { Substitution } from 'models/Substitutions';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteSubstituteItemMutation, useGetSubstituteItemsQuery } from 'redux/services/hermanTheMop/hermanTheMopApi';
import { useAppSelector } from 'redux/store';
import deleteLottie from '../../common/assets/delete-lottie.json';
import { LottieButton } from './LottieButton';
import { SubstitutesFilters } from './SubstitutesFilters';

export const SubstitutesTable = (): JSX.Element => {
  const [take, setTake] = useState(25);
  const [offset, setOffset] = useState(0);
  const { id } = useParams();
  const { isImporting, loadingMessage } = useAppSelector((state) => state.importSlice);
  const { hermanTheMopParams } = useAppSelector((state) => state);
  const { data, isLoading, isFetching, isError, error } = useGetSubstituteItemsQuery({ businessId: id as string, params: { ...hermanTheMopParams, offset, take } }, { skip: !id });

  const [deleteSubstitution, { isLoading: isDeleteing }] = useDeleteSubstituteItemMutation();

  const handlePageChange = (page: number, pageSize: number): void => {
    setTake(pageSize);
    setOffset((page - 1) * pageSize);
  };

  const handleDelete = async (substituteItemId: string): Promise<void> => {
    if (!id)
      return notification.error({
        message: 'Error',
        description: 'No business id found. Please try again.',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
    try {
      await deleteSubstitution({ businessId: id, substituteItemId });
      notification.success({
        message: 'Success',
        description: 'Substitution successfully deleted.',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
    } catch (e) {
      console.error(e);
      const error = e as QueryErrorModel;
      const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
    }
  };

  const cols: ColumnProps<Substitution>[] = [
    {
      title: 'Product Number',
      key: 'itemId',
      dataIndex: 'itemId',
      render: (_, record) => <Typography.Text>{record?.item?.productNumber}</Typography.Text>
    },
    {
      title: 'Substitution Type',
      key: 'substitutionType',
      dataIndex: 'substitutionType'
    },
    {
      title: 'Substitution Product Number',
      key: 'substituteItemId',
      dataIndex: 'substituteItemId',
      render: (_, record) => <Typography.Text>{record?.substituteItem?.productNumber}</Typography.Text>
    },

    {
      title: 'Is Spares Model',
      key: 'isSparesModel',
      dataIndex: 'isSparesModel',
      render: (_, record) => <Typography.Text>{record.isSparesModel ? 'TRUE' : 'FALSE'}</Typography.Text>
    },
    {
      title: 'Order Types',
      key: 'substitutionOrderType',
      dataIndex: 'substitutionOrderType'
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_, record) => (
        <Space>
          <EditRecordModal record={record} />
          <LottieButton
            style={{ borderRadius: '50%', background: '#FFD4D4', borderColor: 'red' }}
            animationData={deleteLottie}
            lottieStyle={{ width: 20, height: 20, display: 'inline-block', transform: 'scale(2.3)' }}
            onClick={(): void => {
              Modal.confirm({ title: 'Are you sure you want to delete this substitution?', onOk: () => handleDelete(record.id) });
            }}
          />
        </Space>
      )
    }
  ];

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SubstitutesFilters />
      <Table
        pagination={{ total: data?.totalCount ?? 0, defaultPageSize: take, onChange: handlePageChange, pageSizeOptions: ['10', '20', '25', '50', '100'] }}
        dataSource={data?.data}
        loading={{
          indicator: <LoaderWithMessage height="0vh" loadingMessage={isImporting ? loadingMessage : isDeleteing ? 'Deleting record' : 'Loading'} />,
          spinning: isLoading || isFetching || isDeleteing || isImporting
        }}
        columns={cols}
        size="small"
      />
    </Space>
  );
};
