import { Card, Col, Row, Typography } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';

export const GeneralInfoCard = (): JSX.Element => {
  return (
    <Card
      headStyle={{ backgroundColor: 'rgb(255, 234, 193)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
          General Information
        </Typography.Title>
      }
      bodyStyle={{ minHeight: '100%' }}
      style={{ height: '100%', minHeight: '100%', width: '100%' }}>
      <Row gutter={[50, 10]}>
        <Col span={12}>
          <FieldInput label="Customer Purchase Order" fieldName="customerPurchaseOrder" />
        </Col>
        <Col span={12}>
          <FieldInputSelect options={[]} label="Vendor Account" fieldName="vendorId" />
        </Col>

        <Col span={12}>
          <FieldInputSelect options={[]} label="Site" fieldName="siteId" />
        </Col>
        <Col span={12}>
          <FieldInputSelect options={[]} label="Warehouse" fieldName="warehouseId" />
        </Col>
      </Row>
    </Card>
  );
};
