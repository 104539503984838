import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import lodash from 'lodash';
import { ApiMultipleResponse } from 'models/ApiModels';
import { Division } from 'models/Division';
import { ProductCatalogItem, ProductCatalogItemPayload, ProductCatalogItemsParams } from 'models/ProductCatalog';
import { ReduxState } from 'redux/store';
import { abbyCadabbyApi } from '../abbyCadabby';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_JULIA_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_JULIA;

export const juliaBaseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as ReduxState).app.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-functions-key', functionsKey);
      headers.set('Content-Type', 'application/json');
    }

    return headers;
  }
});

export const juliaApi = createApi({
  reducerPath: 'juliaApi',
  baseQuery: juliaBaseQuery,
  tagTypes: ['ProductCatalogItem', 'ProductCatalogItems', 'DeletedProductCatalogItem'],
  endpoints: (builder) => ({
    /* ****************** Business Endpoints ****************** */
    createProductCatalogItem: builder.mutation<ProductCatalogItem, { customerId: string; payload: ProductCatalogItemPayload }>({
      query: ({ customerId, payload }) => ({
        url: `customers/${customerId}/productCatalogItems`,
        method: 'POST',
        body: payload,
        responseHandler: (response): Promise<ProductCatalogItem | string> => (response.status >= 300 ? response.text() : response.json())
      }),
      invalidatesTags: ['ProductCatalogItems']
    }),
    getProductCatalogItem: builder.query<ProductCatalogItem, { customerId: string; productId: string }>({
      query: ({ customerId, productId }) => ({
        url: `customers/${customerId}/productCatalogItems/${productId}?includeInactiveData=true`
      }),
      providesTags: ['ProductCatalogItem']
    }),
    checkProductCatalogItemAssigned: builder.query<ApiMultipleResponse<ProductCatalogItem>, { customerId: string; productNumbers: string[] }>({
      query: ({ customerId, productNumbers }) => ({
        url: `customers/${customerId}/productCatalogItems?includeDeletedData=true&includeInactiveData=true&take=${productNumbers.length}&productNumbers=${productNumbers?.join('&productNumbers=')}`
      }),
      providesTags: ['ProductCatalogItems']
    }),
    getProductCatalogItems: builder.query<ApiMultipleResponse<ProductCatalogItem>, { customerId: string; params: ProductCatalogItemsParams }>({
      query: ({ customerId, params }) => ({
        url: `customers/${customerId}/productCatalogItems`,
        params
      }),
      providesTags: ['ProductCatalogItems'],
      onCacheEntryAdded: async (_arg, _api) => {
        try {
          await _api.cacheDataLoaded;
          const { originalArgs } = _api.getCacheEntry();
          const { queries } = _api.getState().juliaApi;
          const lastQueryArgs = { ...originalArgs, params: { ...originalArgs?.params, offset: _arg.params.offset - 25 } };
          const lastQueryData = Object.values(queries).find((value) => lodash.isEqual(value?.originalArgs, lastQueryArgs));

          if (lastQueryData) {
            _api.updateCachedData((draft) => {
              draft.data.unshift(...(lastQueryData.data as any).data);
            });
          }
        } catch (error) {
          //
        }
      }
    }),
    updateProductCatalogItem: builder.mutation<ProductCatalogItem, { customerId: string; productId: string; payload: ProductCatalogItem }>({
      query: ({ customerId, productId, payload }) => ({
        url: `customers/${customerId}/productCatalogItems/${productId}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: ['ProductCatalogItem', 'ProductCatalogItems']
    }),
    deleteProductCatalogItem: builder.mutation<void, { customerId: string; productId: string }>({
      query: ({ customerId, productId }) => ({
        url: `customers/${customerId}/productCatalogItems/${productId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ProductCatalogItems', 'ProductCatalogItem', 'DeletedProductCatalogItem']
    }),
    purgeProductCatalogItem: builder.mutation<void, { customerId: string; productId: string }>({
      query: ({ customerId, productId }) => ({
        url: `customers/${customerId}/productCatalogItems/${productId}?wouldYouLikeToPlayAGame=true`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ProductCatalogItems', 'ProductCatalogItem', 'DeletedProductCatalogItem']
    }),
    getDeletedProductCatalogItems: builder.query<ApiMultipleResponse<ProductCatalogItem>, { customerId: string; params: ProductCatalogItemsParams }>({
      query: ({ customerId, params }) => ({
        url: `customers/${customerId}/productCatalogItems`,
        params: { ...params, returnDeletedDataOnly: true } as ProductCatalogItemsParams
      }),
      providesTags: ['DeletedProductCatalogItem'],
      onCacheEntryAdded: async (_arg, _api) => {
        try {
          await _api.cacheDataLoaded;
          const { originalArgs } = _api.getCacheEntry();
          const { queries } = _api.getState().juliaApi;
          const lastQueryArgs = { ...originalArgs, params: { ...originalArgs?.params, offset: _arg.params.offset - 25 } };
          const lastQueryData = Object.values(queries).find((value) => lodash.isEqual(value?.originalArgs, lastQueryArgs));

          if (lastQueryData) {
            _api.updateCachedData((draft) => {
              draft.data.unshift(...(lastQueryData.data as any).data);
            });
          }
        } catch (error) {
          //
        }
      }
    }),
    recoverProductCatalogItem: builder.mutation<ProductCatalogItem, { customerId: string; productId: string }>({
      query: ({ customerId, productId }) => ({
        url: `customers/${customerId}/productCatalogItems/${productId}/recover`
      }),
      invalidatesTags: ['DeletedProductCatalogItem', 'ProductCatalogItems']
    }),
    updateDivisionInheritanceLevel: builder.mutation<Division, { divisionId: string; payload: { dataInheritanceLevel: 'Business' | 'Division' | 'BusinessWithDivisionOverride' } }>({
      query: ({ divisionId, payload }) => ({
        url: `customers/${divisionId}/dataInheritanceLevel`,
        method: 'PUT',
        body: payload
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        await queryFulfilled;
        dispatch(abbyCadabbyApi.util.invalidateTags(['Division']));
      },
      invalidatesTags: ['ProductCatalogItems', 'ProductCatalogItem']
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),

    createCustomHttpStatus: builder.mutation<string, { statusCode: number; message: string }>({
      query: ({ statusCode, message }) => ({
        url: `diagnostics/customHttpStatus/${statusCode}/${message}`,
        method: 'POST',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const {
  useGetProductCatalogItemsQuery,
  useCreateProductCatalogItemMutation,
  useDeleteProductCatalogItemMutation,
  usePurgeProductCatalogItemMutation,
  useGetProductCatalogItemQuery,
  useUpdateProductCatalogItemMutation,
  useGetDeletedProductCatalogItemsQuery,
  useRecoverProductCatalogItemMutation,
  useLazyGetProductCatalogItemQuery,
  useCheckProductCatalogItemAssignedQuery,
  useUpdateDivisionInheritanceLevelMutation,
  useGetVersionQuery,
  useGetApiNameQuery,
  useLazyGetProductCatalogItemsQuery,
  useCreateCustomHttpStatusMutation
} = juliaApi;
