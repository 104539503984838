import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, Tooltip, Typography } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import Upload, { RcFile } from 'antd/es/upload';
import { SubstitutionImportPayload } from 'models/Substitutions';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { LineItemError, useGetSubstituteItemsQuery, useImportSubstitutionMutation } from 'redux/services/hermanTheMop/hermanTheMopApi';
import { useAppSelector } from 'redux/store';
import { read, utils, writeFile } from 'xlsx';

export const ImportExportButtonGroup: FC = () => {
  const { id } = useParams();
  const { hermanTheMopParams } = useAppSelector((state) => state);
  const { data } = useGetSubstituteItemsQuery({ businessId: id as string, params: hermanTheMopParams }, { skip: !id });
  const [importSubstitution] = useImportSubstitutionMutation();
  const { isImporting } = useAppSelector((state) => state.importSlice);

  const handleErrorModal = (importErrors: LineItemError[]): void => {
    Modal.confirm({
      title: 'Import Errors',
      bodyStyle: { maxHeight: 800 },
      cancelText: 'Export',
      onCancel: () => {
        const worksheeet = utils.json_to_sheet(importErrors);
        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheeet, 'assets');
        writeFile(workbook, 'substitution-import-errors.xlsx');
      },
      width: 1000,
      icon: <></>,
      content: (
        <div style={{ border: '1px solid rgba(0,0,0,.1)', borderRadius: 10, padding: 10, marginTop: 20, overflow: 'scroll', height: 600 }}>
          <Row style={{ marginTop: 20, marginLeft: 5, marginBottom: 5 }}>
            <Col span={5}>
              <Typography.Text style={{ fontSize: 15 }}>Header</Typography.Text>
            </Col>
            <Col span={19}>
              <Typography.Text style={{ fontSize: 15 }}>Message</Typography.Text>
            </Col>
          </Row>
          {importErrors.map((error, i) => (
            <Row gutter={[10, 10]} key={error.errorMessage + i} style={{ padding: 10, background: 'rgba(0,0,0,.1)', borderRadius: 10, marginBottom: 10 }}>
              <Col span={5}>{error.title}</Col>
              <Col span={19}>{error.errorMessage}</Col>
            </Row>
          ))}
        </div>
      )
    });
  };

  const handleImport = async (importData: SubstitutionImportPayload[]): Promise<void> => {
    if (!id) return;
    const res = await importSubstitution({ payload: importData, customerId: id }).unwrap();

    !!res.errors.length && handleErrorModal(res.errors);
  };

  const parseData = async (file: RcFile): Promise<void> => {
    const reader = new FileReader();

    reader.onload = (e): void => {
      if (e.target && e.target.result !== null) {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });

        const sheetNameAssetHeader = workbook.SheetNames[0];

        const worksheetAssetHeader = workbook.Sheets[sheetNameAssetHeader];

        const jsonAssetHeader = utils.sheet_to_json(worksheetAssetHeader, { raw: false });

        const payload: SubstitutionImportPayload[] = jsonAssetHeader.map((item: any) => ({
          itemId: item['Product Number'],
          substitutionType: item['Substitution Type'],
          SubstituteItemId: item['Substitution Product Number'],
          isSparesModel: item['Is Spares Model'],
          substitutionOrderType: item['Order Type']
        }));

        handleImport(payload);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleExport = (): void => {
    const dataToExport = data?.data.map(({ item, substitutionType, substituteItem, isSparesModel, substitutionOrderType }) => {
      return {
        'Product Number': item.productNumber,
        'Substitution Type': substitutionType,
        'Substitution Product Number': substituteItem.productNumber,
        'Is Spares Model': isSparesModel ? 'TRUE' : 'FALSE',
        'Order Type': substitutionOrderType
      };
    });
    const worksheeet = utils.json_to_sheet(dataToExport ?? []);
    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheeet, 'substitutions');
    writeFile(workbook, 'substitution-export.xlsx');
  };

  return (
    <Space size={3}>
      <ButtonGroup>
        <Tooltip title="Download import template">
          <Button disabled={isImporting} download href={`/SubstitutionImportTemplate.xlsx`}>
            <DownloadOutlined />
          </Button>
        </Tooltip>
        <Button disabled={isImporting} loading={isImporting}>
          <Upload beforeUpload={parseData} showUploadList={false}>
            Import
          </Upload>
        </Button>
      </ButtonGroup>
      <Button disabled={isImporting} onClick={handleExport}>
        Export
      </Button>
    </Space>
  );
};
